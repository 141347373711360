<template>
    <div class="h-full pb-10">
        <div class="flex gap-2 items-center">
            <h3 class="text-xl font-bold">{{$route.name}}</h3>
        </div>

        <a-spin :spinning="loading">
            <div class="content bg-white shadow rounded mt-2 p-3 h-full relative">
                <div class="">
                    <div>
                        <label class="block font-semibold">Supplier</label>
                        <a-select v-if="suppliers.length" :options="suppliers" class="w-full" placeholder="Select Supplier" v-model:value="selectedSupplier" @change="getPos"></a-select>
                        <div v-else>No Suppliers Found</div>
                    </div>

                    <div class="mt-2 ">
                        <label class="block font-semibold">PO</label>
                        <a-select v-if="pos.length" :options="pos" class="w-full" placeholder="Select Supplier" v-model:value="selectedPo" @change="getPo"></a-select>
                        <div v-else>No PO's Found</div>
                    </div>

                    <div v-if="po" class="mt-4">
                        <ul>
                            <li v-for="p in po" :key="p.pkPurchaseItemId" class="mb-2"><span class="font-semibold">{{p.SKU}}</span> - {{p.ItemTitle}}</li>
                        </ul>
                    </div>

                    <div class="mt-3" v-if="po">
                        <a-button type="primary" class="w-full" @click="processToFab">Process to Fab</a-button>
                    </div>
                </div>
            </div>
        </a-spin>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'ProcessFab',
    data() {
        return {
            suppliers: [],
            selectedSupplier: "4573ec7c-d31f-4394-a06a-762980455739",
            pos: [],
            selectedPo: null,
            po: null,
            loading: false,
        }
    },
    components: {
    },
    mounted() {
        this.getSuppliers()
    },
    computed: {
    },
    methods: {
        getSuppliers() {
            this.loading = true;
            this.$http.get("https://darkside_api.test/api/dispatch/fab/suppliers").then((resp) => {
                if(resp.data.success) {
                    this.suppliers = resp.data.data
                    this.getPos();
                }
            });
        },
        getPos() {
            if(!this.loading) {
                this.loading = true;
            }
            this.$http.get("https://darkside_api.test/api/dispatch/fab/pos?supplier="+this.selectedSupplier).then((resp) => {
                if(resp.data.success) {
                    this.pos = resp.data.data
                    this.loading = false;
                }
            });
        },
        getPo(e) {
            this.loading = true;
            this.$http.get("https://darkside_api.test/api/dispatch/fab/po?po="+e).then((resp) => {
                if(resp.data.success) {
                    this.po = resp.data.data
                    this.loading = false;
                }
            });
        },
        processToFab() {
            this.loading = true;
            this.$http.post("https://darkside_api.test/api/dispatch/fab/process", {
                po: this.selectedPo
            }).then((resp) => {
                if(resp.data.success) {
                    this.loading = false;
                }
            });
        }
    },
    watch: {
    }
})
</script>

<style lang="scss">
</style>
